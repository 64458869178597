import environment from "consts:environment";

// used for interop with old js
const stores = new Map();
// @ts-ignore
window.stores = stores;

const prefix = "Svelte";
const subitemColor = `color: #889;
    font-size: 10px;
    font-weight: 600;
    letter-spacing:1px;
    margin-bottom:4px;
    text-transform: uppercase;
`;
const nullColor = "color: #888;opacity: 0.2;";
const componentNameColor = "color: #6bb;";
const svelteColor = `
    color: #fff;
    background-color: #ff3e00;
    border-radius: 6px;
    font-size: 6px;
    margin-bottom:6px;
    padding: 1px 0.5em;
    text-transform: uppercase;
`;

/**
 * We create a temporary root to mount the component at.
 * Actual implementation may require creating a facade for not having any root element.
 * That way, the component is mounted directly between the script tags without intermediate element.
 * @param {Element} target
 * @returns
 */
const createRoot = (target) => {
    const parent = target.parentNode;
    const span = document.createElement("span");
    span.id = `${target.id}-root`;
    parent.insertBefore(span, target);
    return span;
};

/**
 * Instantiates and hydrates a component
 * @param {any} Component
 * @param {Element} target
 * @param {unknown} props
 * @returns
 */
export const hydrate = (Component, target, props) => {
    const root = createRoot(target);

    if (environment === "development") {
        console.groupCollapsed(
            `%c${prefix}%c hydrating %c${Component.name}%c.svelte`,
            svelteColor,
            nullColor,
            componentNameColor,
            nullColor
        );
        console.log(`%cInstance`, subitemColor, target.id);
        console.log(`%cRoot`, subitemColor, root);
        if (props !== null) {
            console.group(`%cProps`, subitemColor);
            console.dirxml(props);
            console.groupEnd();
        } else {
            console.log(`%cProps is`, nullColor, props);
        }
        console.groupCollapsed("%cDetails", subitemColor);
        console.log({ target, props, root, Component });
        console.groupEnd();
        console.groupEnd();
    }

    return new Component({
        target: root,
        props,
        hydrate: true,
    });
};

export const initStore = (store, data, name, nameAndPath) => {
    if (environment === "development") {
        // @ts-ignore
        if (stores.has(name)) {
            throw new Error(`A store should only be set once per page ("${nameAndPath}.js")`);
        }

        if (data === null) {
            console.groupCollapsed(`${prefix} Using "${nameAndPath}.js" for interop`);
            console.warn(
                `If you're seeing this and NOT using "${nameAndPath}.js" in legacy JS: You can simply import the store directly instead of using the @Svelte.Store( ... ) directive.`
            );
            console.log("ex:");
            console.log(`%c\timport { ${name} } from "@stores/${nameAndPath}.js";`, "font-family: monospace;");
            console.groupEnd();
        } else {
            console.groupCollapsed(`%c${prefix}%c initializing %c${name}.js`, svelteColor, nullColor, componentNameColor);
            console.log(`%cdata`, subitemColor, data);
            console.groupEnd();
        }
    }

    // CASE: when data is null we are likely using this store for interop in JS,
    //       thus the data doesn't matter
    if (data !== null) {
        store.set(data);
    }

    // below is interop with old JS
    stores.set(name, store);
    document.body.dispatchEvent(new CustomEvent("interop:store-added", { detail: { name } }));

    return store;
};
